@import "src/scss/Vars";

.item-music {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  font-size: 1.2rem;
  color: white;

  .image {
    position: relative;
    margin-bottom: 1.5rem;
    border-radius: 20px;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    a.image:hover {
      color: red;
    }
  }

  .name {
    margin-bottom: 0.5rem;
  }
}
