@import "src/scss/Vars";

.site-navigation {
  .container {
    display: flex !important;
    alignment: center;

    .btn-custom {
      align-self: flex-end;
      float: right;
    }
  }
}

.message {
  font-weight: 400;
  margin: 20px;
}

@include mobile {
  .site-navigation {
    width: 100%;
  }
}