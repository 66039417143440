@import "src/scss/Vars";

.top-wrapper {
  background: #121622 url("/images/header_blue.png") no-repeat bottom center;
  padding-bottom: 8rem !important;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;

    h1 {
      margin-bottom: 2rem;
    }

    .text {
      max-width: 810px;
      margin-bottom: 2rem;
      text-align: center;
      line-height: 1.5;
    }
  }
}

@include tablet {
  .top-wrapper {
    padding-bottom: 3rem !important;
    .top {
      padding: 2rem 0;
      h1 {
        text-align: center;
      }
    }
  }
}
