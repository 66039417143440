// colors
$error: #de351b;
$button: #2a9a68;
$color-blue: blue;
$color-black: black;
$h3: #F2BE22;
$link: #37CDFC;

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin pad($top, $bottom: $top) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin mar($top, $bottom: $top) {
  margin-top: $top;
  margin-bottom: $bottom;
}
