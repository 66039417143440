button.btn-custom {
  border: 0;
  box-sizing: border-box;
  position: absolute;
  background: #FFFFFF;
  border-radius: 55px;
  transition-duration: .3s;
  color: #000000;

  &:hover {
    color: #FFFFFF;
    background-color: #7E4EFF;
  }
}