@import "src/scss/Vars";

.defi-wrapper {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  background: #121622 url("/images/hoge_defi.png") no-repeat top -100px right 20%;
  background-size: cover;

  .buy {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    z-index: 100;
    height: 45px;
    line-height: 45px;
  }

  .defi {
    padding-top: 5rem;
    flex-direction: column;

    .defi-content {
      max-width: 550px;
      margin-bottom: 6rem;
    }

    .title,
    .subtitle {
      font-style: normal;
      font-weight: 900;
      //font-size: 80px;
      //line-height: 90px;
      text-align: left;
      text-shadow:
        -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white,
        -1px -1px white, 1px 1px white, -1px 1px white, 1px -1px white;
    }

    .subtitle {
      color: #7E4EFF;
      text-shadow:
        -1px 0 #7E4EFF, 0 1px #7E4EFF, 1px 0 #7E4EFF, 0 -1px #7E4EFF,
        -1px -1px #7E4EFF, 1px 1px #7E4EFF, -1px 1px #7E4EFF, 1px -1px #7E4EFF;
    }
    .text {
      margin-bottom: 1.5rem;
      line-height: 1.5;
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-around;
      text-transform: uppercase;
      font-size: 1.1rem;
    }
  }
}

.certik-wrapper {
  margin-top: auto;
  background: #151515;

  .certik {
    align-items: center;
    flex-direction: row;
    font-size: 1.2rem;
    padding: 3rem 0;

    .text {
      color: white;
      margin: 0 2rem 0 0;
    }

    .certik-logo {
      width: 200px;
      height: 48px;
      background: url("/images/certik.png") no-repeat;
    }

    a {
      margin-left: auto;
    }
  }
}

@include tablet {
  .defi-wrapper {
    background-size: auto 500px;
    background-position-y: initial;

    .defi {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .defi-content {
        padding-top: 400px;
        max-width: initial;
        margin-bottom: 3rem;

        .title,
        .subtitle {
          text-align: center;
        }
        .text {
          text-align: center;
        }
        .buttons {
          flex-wrap: wrap;
          justify-content: center;
          gap: 1rem;
        }
      }
    }
  }

  .certik-wrapper {

    .certik {
      flex-direction: column;
      padding: 3rem 0;
      gap: 1rem;

      .certik-logo {
        margin-bottom: 1rem;
      }

      a {
        margin: 0;
      }
    }
  }
}
