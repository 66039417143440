@import "src/scss/Vars";

.team-top-wrapper {
  background: #121622 url("/images/header_blue.png") no-repeat bottom center;
  padding-bottom: 8rem !important;

  .team-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;

    h1 {
      margin-bottom: 2rem;
    }

    .text {
      max-width: 810px;
      margin-bottom: 2rem;
      text-align: center;
      line-height: 1.5;
    }
  }
}

@include tablet {
  .team-top-wrapper {
    padding-bottom: 0 !important;
    .team-top {
      padding: 2rem 0;
      h1 {
        text-align: center;
      }
    }
  }
}