@import "src/scss/Vars";

.header-wrapper {
  position: relative;
  .buy {
    position: absolute;
    top: 1.5rem;
    right: 2.5rem;
    z-index: 100;
    height: 45px;
    line-height: 45px;
  }
}

@include tablet {
  .header-wrapper {
    .buy {
      left: 50%;
      width: 9rem;
      padding: 0 1rem;
      transform: translate(-50%, 0);
    }
  }
}

