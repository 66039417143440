@import "src/scss/Vars";

.hoge-project {
  display: flex;
  flex-direction: column;
  background: #121622;
  color: #fff;

    .project {
      justify-content: space-between;
      align-items: center;
      @include pad(8rem);
      flex-flow: wrap;
      gap: 2rem;

      .left {
        max-width: 500px;
        .text {
          margin-bottom: 3rem;
        }
      }
      .right {
        max-width: 750px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .infos {
      @include pad(3rem);
      @include mar(0, 8rem);
      width: 100%;
      display: flex;
      background: #1A1F2D;
      border: 1px solid #31384F;
      border-radius: 30px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          max-width: 210px;
          font-weight: 300;

          .image {
              width: 80px;
              height: 80px;
          }
          .value {
              font-size: 50px;
              line-height: 63px;
              color: #A887FF;
          }
          .text{
              font-size: 1.2rem;
          }

          &.info-1 .image {
              background: url("/images/fire.png");
          }
          &.info-2 .image {
              background: url("/images/diamond.png");
          }
          &.info-3 .image {
              background: url("/images/coin.png");
          }
        }
    }

  .tokens-wrapper {
    background: url("/images/hoge_tokens.png") no-repeat center left 10%;

    .tokens {
      justify-content: space-between;
      align-items: center;
      @include pad(8rem);

      .right {
        max-width: 600px;

        > .text {
          margin-bottom: 2rem;
        }
      }

      .funds {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1.5rem 2rem;
        flex-direction: row;
        border: 2px solid #31384F;
        border-radius: 30px;
        align-content: center;

        .optivault {
          width: 98px;
          height: 90px;
          background: url("/images/art-blobby.svg");
          margin-right: .4rem;
        }

        .text {
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 1.8rem;
          flex: 1;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        .icon {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

@include tablet {
  .hoge-project {

    .project {
      @include pad(3rem);
    }

    .infos {
      flex-direction: column;
      gap: 3rem;
      margin: 0 2rem;
    }

    .tokens-wrapper {
      background-image: none;

      .tokens {
        @include pad(3rem);
      }
    }
  }
}
