@import "src/scss/Vars";

.carousel-top {
  width: 100%;
  padding-bottom: 3rem;

  .text {
    text-align: left;
    width: 100%;
  }
}
