@import "src/scss/Vars";

.media-wrapper {
  background: #1a1f2d;

  .media {
    flex-direction: column;
    text-align: center;
    color: #fff;
    padding: 5rem 0;

    .links {
      display: flex;
      justify-content: space-around;
      padding: 4.5rem 0;
      align-items: center;

      a.yahoo_finance {
        background: url("/images/yahoo_finance.png") center;
        background-size: cover;
        width: 200px;
        height: 75px;
      }
      a.bloomberg {
        background: url("/images/bloomberg.png") center;
        background-size: cover;
        width: 200px;
        height: 38px;
      }
      a.usa_today {
        background: url("/images/usa_today.png") center;
        background-size: cover;
        width: 200px;
        height: 72px;
      }
    }

    .articles {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

@include tablet {
  .media-wrapper {

    .media {
      padding: 2rem 0;

      h3 {
        margin-bottom: 2.5rem;
      }

      .links {
        padding: 0;
        flex-direction: column;
        gap: 3rem;
      }
    }
  }
}