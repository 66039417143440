@import "src/scss/Vars";

.rider-wrapper {
  background: url("/images/hoge_footer.png") center;
  background-size: cover;
  height: 1080px;
  display: flex;
  flex-direction: column;

  a.roadmap {
    margin: auto;
  }
}

@include tablet {
  .rider-wrapper {
    height: 600px;
  }
}

