@import "src/scss/Vars";

.ecosystem-wrapper {
  display: flex;
  flex-direction: column;
  color: white;
  background: #121622;

  .ecosystem {
    justify-content: center;
    flex-direction: column;
    @include pad(9rem, 0);

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .text {
        max-width: 600px;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 6rem;
    }

    .more {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

@include tablet {
  .ecosystem-wrapper {

    .ecosystem{
      @include pad(3rem);

      .list {
        margin-bottom: 0;
      }
    }
  }
}