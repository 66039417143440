@import "src/scss/Vars";

.item-game {
  display: flex;
  flex-direction: column;
  width: 275px;
  font-size: 1.2rem;

  .item-game-inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem;
  }

  a.image {
    margin-bottom: 1.5rem;
    img {
      max-width:100%;
      max-height:100%;
      border-radius: 20px;
      border: 2px solid #fff;
    }
  }

  .name {
    margin-bottom: 1rem;
  }

  .text {

  }
}