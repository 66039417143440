@import "src/scss/Vars";

.carousel-wrapper {
  display: flex;
  flex-direction: column;
  color: white;
  background: #1A1F2D;

  .carousel-container {
    justify-content: center;
    flex-direction: column;
    @include pad(3rem, 8rem);
  }

  .top {
    display: flex;
    flex-direction: column;
  }
}

.carousel {
  min-height: 350px;

  .carousel-indicators {
    margin: 0;
    bottom: -5rem;
    button {
      width: 100px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 30px;
    width: 52px;
    background: rgba(255, 255, 255, 0.3);;
  }
  .carousel-control-prev {
    left: auto;
    right: 52px;
    border-radius: 60px 0 0 60px;
  }
  .carousel-control-next {
    border-radius: 0 60px 60px 0;
  }

  .carousel-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .carousel-top {
      width: 100%;
      padding-bottom: 3rem;
    }

    .carousel-bottom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
      flex-wrap: wrap;

      .text {
        text-align: left;
        flex: 1;
      }

      .image {
        img {
          border-radius: 35px;
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          max-height: 500px;
        }
      }
    }
  }
}

@include tablet {
  .carousel-wrapper {

    .carousel-container {
      @include pad(3rem);
      padding-bottom: 5rem;
    }
  }

  .carousel {

    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }

    .carousel-indicators {
      bottom: -2rem;
    }

    .carousel-content {
      .carousel-bottom {
        .text {
          text-align: center;
          order: 2;
        }
      }
    }

  }
}
