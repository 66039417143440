@import "~bootstrap/scss/bootstrap";
@import "Vars";

body {
  font-family: "Sora", Arial, serif;
  font-size: 18px;
  line-height: 1.25; //22px
  background: #121622;
  color: #FFFFFF;
}

h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 5rem;
  line-height: 1.1;
  text-shadow:
    -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white,
    -1px -1px white, 1px 1px white, -1px 1px white, 1px -1px white;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: $h3;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1rem;
  color: $h3;
}

a {
  color: $link;
  transition: 0.5s;
}

.container-fluid {
  padding: 0;
}

.container {
  display: flex;

  .image-full {
    max-width: 100%;
    margin-bottom: 3rem;
  }
}

.btn {
  padding: 0 2rem;
  line-height: 60px;
  height: 60px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border: 1px solid #FFFFFF;
  border-radius: 55px;
  color: #FFFFFF;
  transition: 0.5s ease;
  font-weight: 600;
  &.small {
    height: 45px;
    line-height: 45px;
  }

  &:hover {
    background: #FFFFFF;
    color: #000000;
  }
}

a.btn-purple {
  @extend .btn;
  background: #7E4EFF;
}

a.btn-secondary {
  @extend .btn;
  background: #FFFFFF;
  color: #000000;
  &:hover {
    color: #FFFFFF;
    background: initial;
    border-color: #FFFFFF;
  }
}

a {
  > .icon {
    margin-left: 0.5rem;
  }
}

.separator {
  flex-grow: 1;
  width: 100%;
  height: 2px;
  background: #31384F;
  margin: 2rem 0;
}

.separator-gradient {
  flex-grow: 1;
  width: 100%;
  height: 2px;
  margin: 0;
  background: linear-gradient(to left, #2B1871 0%, #2094D4 45%, #DC70F0 100%);
}

.text-bg{
  background: #14182445;
  padding-left: 2%;
  border-radius: 16px;
}


@include tablet {
  body {
    font-size: 16px;
    line-height: 1.1; //20px
  }

  h1 {
    font-size: 3rem;
  }

  .container {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
