@import "src/scss/Vars";

.officers-wrapper {
  .officers {
    flex-direction: column;
    padding: 5rem 0;
    &#Leadership {
      padding-top: 0;
    }

    .title {
      font-size: 2rem;
    }

    .separator {
      margin-top: 0;
    }

    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}

@include tablet {
  .officers-wrapper {
    .officers {
      padding: 2rem 0;
      &#Leadership {
        padding-top: 2rem;
      }
    }
  }
}
