@import "src/scss/Vars";

.footer-wrapper {
  background: #0C0E14;
  .footer {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 0.9rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .inquiries {
      margin: 2rem 0 0;
    }

    .links {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 3rem;
      margin: 2rem 0;

      a {
        color: white;
        text-decoration: none;
        font-size: 1.6rem;
        &:hover {
          color: $link;
        }
      }
    }

    .note {
      display: flex;
      width: 100%;
      font-size: 0.5rem;
    }
  }

  .social-wrapper {
    align-self: flex-end;

    .social {
      font-size: 1.2rem;
      color: white;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      height: fit-content;

      .text {
        font-size: calc(1vw + 1em);
        color: #F2BE22;
      }

      .links {
        display: grid;
        gap: 3vw;
        grid-template: "a b c d e"; //auto 1fr / auto 1fr auto;
        align-content: space-evenly;
        img {
          width: 10vw;
          min-width: 60px;
          max-width: 100px;
        }
      }
    }
  }
}

@include tablet {
  .footer-wrapper {
    .footer {
      height: auto;
      .inquiries {
        margin: 0;
      }
      .links {
        margin: 1rem 0;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .footer-wrapper {
    .footer{
      .social-wrapper {
        .social {
          .links {
            grid-template-columns: 100px;
            grid-template-rows: auto;
            margin: 1rem 0;
            flex-direction: row;
            gap: 1rem;
          }
        }
      }
    }
  }
}
