@import "src/scss/Vars";

.collection-wrapper {
  display: flex;
  flex-direction: column;
  color: white;
  background: #121622;

  .collection{
    flex-direction: column;
    @include pad(9rem);

    .list {
      display: flex;
      width: 100%;
      gap: 2rem;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

@include tablet {
  .collection-wrapper {

    .collection{
      @include pad(3rem);

      .list {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
}