@import "src/scss/Vars";

.prints-wrapper {
  display: flex;
  flex-direction: column;
  color: white;
  background: #121622;

  .prints {
    flex-direction: column;

    .list {
      display: flex;
      width: 100%;
      gap: 2rem;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

@include tablet {
  .prints-wrapper {

    .prints {

      .list {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
}
