@import "src/scss/Vars";

.layout {

  &.home {
    .header-wrapper {
      background: rgb(125, 78, 254, 0.4);
    }
  }
  &.gaming {
    .header-wrapper {
      background: #121622;
    }
  }
  &.about {
    .header-wrapper {
      background: rgba(29, 127, 183, 0.4);
    }
  }

  .page {
    width: 100%;
    min-height: 100vh;
  }
}

@include mobile {
  .layout {
    &.home {
      .header-wrapper {
        background: transparent;
      }
    }
  }
}